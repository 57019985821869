import { render, staticRenderFns } from "./LeadCaptureList.vue?vue&type=template&id=cf637a4e&scoped=true"
import script from "./LeadCaptureList.vue?vue&type=script&lang=js"
export * from "./LeadCaptureList.vue?vue&type=script&lang=js"
import style0 from "./LeadCaptureList.vue?vue&type=style&index=0&id=cf637a4e&prod&lang=scss&scoped=true"
import style1 from "./LeadCaptureList.vue?vue&type=style&index=1&id=cf637a4e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf637a4e",
  null
  
)

export default component.exports