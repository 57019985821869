<template>
  <div>
    <qr-code-sidebar />
    <new-campaign-sidebar />
    <!-- Filters -->
    <b-card no-body>
      <div class="m-md-2 mb-2 mx-2 mt-0">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mb-0">Exibir</label>
            <v-select
              v-model="itemsPerPage"
              dir="ltr"
              :options="itemsPerPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="search(currentPage)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <label class="mb-0">itens</label>
          </b-col>
          <b-col v-if="canCreate" cols="12" md="6" class="d-flex align-items-center justify-content-end">
            <b-button
              variant="primary"
              @click.prevent="newCampaign"
            >
              <feather-icon icon="PlusCircleIcon" class="mr-50" />
              Nova campanha
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Não foram encontradas campanhas para esta busca"
        :busy="loading.table"
        :no-local-sorting="true"
        :sort-by="sortField"
        @sort-changed="sort"
      >
        <!-- Column: Apelido -->
        <template #cell(alias)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.alias }}</span>
          </div>
        </template>
        <!-- Column: Link -->
        <template #cell(link)="data">
          <div class="text-wrap">
            <span class="align-text-top">
              <a target="_blank" :href="getLink(data.item)">
                {{ getLink(data.item) }}
              </a>
              <b-button
                :id="`campaign-copy-` + data.item.id"
                class="btn-icon mr-1"
                variant="flat"
                style="padding: 0"
                @click="copyURL(data.item)"
              >
                <feather-icon class="text-body" icon="CopyIcon" />
              </b-button>
              <b-tooltip
                :target="`campaign-copy-` + data.item.id"
                triggers="hover"
              >
                Copiar link
              </b-tooltip>
            </span>
          </div>
        </template>
        <!-- Column: QTD Leads -->
        <template #cell(lead_count)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top">{{ data.item.lead_count }}</span>
          </div>
        </template>
        <!-- Column: Status -->
        <template #cell(active)="data">
          <div class="text-wrap">
            <b-form-checkbox
              v-if="canUpdate"
              v-model="data.item.is_active"
              class="mr-0 mt-50"
              switch
              @change="toggleActive(data.item)"
            />
          </div>
        </template>
         <!-- Column: Data de criação -->
         <template #cell(created_at)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.created_at | dateTime }}</span>
          </div>
        </template>
        <template #cell(actions)="data">
          <div class="text-wrap text-center">
            <b-dropdown
              variant="link"
              no-caret
              right
              toggle-class="pl-0"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                @click="generateQR()"
              >
                <feather-icon class="text-body" icon="ExternalLinkIcon" />
                <span class="align-middle ml-50">Gerar código QR</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="canDelete && data.item.lead_count == 0"
                @click="remove(data.item.id)"
              >
                <feather-icon class="text-body" icon="TrashIcon" />
                <span class="align-middle ml-50">Excluir</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Exibindo {{ pageItemsFrom }} à {{ pageItemsTo }} de
              {{ totalItems }} itens</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="itemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="search"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
  BFormGroup
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import useAppConfig from "@core/app-config/useAppConfig";
import QrCodeSidebar from "@/modules/shared/components/QrCodeSidebar";
import NewCampaignSidebar from "../components/NewCampaignSidebar";
import { CAMPAIGN, CREATE_ACTION, DELETE_ACTION, UPDATE_ACTION } from "@/constants/resources";
import * as accountTypes from "@/modules/account/store/types";
import {APP_ADMIN_ROLE_ID, APP_BACKOFFICE_ROLE_ID, APP_FINANCIAL_ROLE_ID, APP_MASTER_ROLE_ID} from "@/constants/auth";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BFormGroup,
    vSelect,
    QrCodeSidebar,
    NewCampaignSidebar
  },
  setup() {
    const { skin } = useAppConfig();
    const campaignBaseUrl = process.env.VUE_APP_CASH_FLOW_FRONTEND_URL + "/seja-futuro/"
    const campaignBaseHRefUrl = process.env.VUE_APP_CASH_FLOW_FRONTEND_URL
    return { toast: useToast(), skin, campaignBaseUrl, campaignBaseHRefUrl };
  },
  data() {
    return {
      loading: {
        table: false
      },
      tableColumns: [
        { key: "alias", label: "Apelido", sortable: true },
        { key: "link", label: "Link"},
        { key: "lead_count", label: "Qtde leads", sortable: true},
        { key: "active", label: "Ativo"},
        { key: "created_at", label: "Data de criação", sortable: true },
        { key: "actions", label: "Ações" }
      ],
      currentPage: 1,
      itemsPerPage: 1,
      order: undefined,
      sortField: null
    };
  },
  computed: {
    ...mapGetters({
      campaigns: types.CAMPAIGNS_LIST,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
      user: accountTypes.USER,
    }),
    canCreate() {
      return this.$can(CREATE_ACTION, CAMPAIGN);
    },
    canUpdate() {
      return this.$can(UPDATE_ACTION, CAMPAIGN);
    },
    canDelete() {
      return this.$can(DELETE_ACTION, CAMPAIGN);
    },
    items: function () {
      return this.campaigns?.data || [];
    },
    totalItems: function () {
      return this.campaigns?.total || 0;
    },
    pageItemsFrom: function () {
      return this.campaigns?.from || 0;
    },
    pageItemsTo: function () {
      return this.campaigns?.to || 0;
    },
  },
  mounted() {
    this.itemsPerPage = this.initialItemsPerPage;
    this.search();
  },
  methods: {
    ...mapActions({
      getcampaigns: types.GET_CAMPAIGNS_LIST,
      deletecampaign: types.DELETE_CAMPAIGN,
      openQrCodeSidebar: sharedTypes.OPEN_QR_CODE_SIDEBAR,
      openNewCampaignSidebar: types.OPEN_NEW_CAMPAIGN_SIDEBAR,
      updatecampaignStatus: types.UPDATE_CAMPAIGN_STATUS
    }),
    search(currentPage = undefined) {
      this.loading.table = true;
      const {
        order,
        itemsPerPage
      } = this
      this.getcampaigns({
        order,
        currentPage: Number.isInteger(currentPage)
          ? currentPage
          : this.currentPage,
        limit: itemsPerPage
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar as campanhas. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
    newCampaign(){
      this.openNewCampaignSidebar({
        saveAction: this.search
      })
    },
    getLink(campaign){
      return this.campaignBaseUrl + campaign.consultant_alias + "/" + campaign.alias
    },
    getLinkHRef(){
      return this.campaignBaseHRefUrl + "/register?code=" + this.user?.appify_consultant_id
    },
    remove(id) {
      this.deletecampaign(id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "A campanha foi removida com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          })
          this.search()
        })
        .catch((err) => {
          if(err.response.status == 405){
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: err.response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }else{
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao remover a campanha. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
    },
    generateQR(){
      this.openQrCodeSidebar ({
        url: this.getLinkHRef()
      })
    },
    toggleActive(campaign) {
      this.updatecampaignStatus({
        id: campaign.id,
        is_active: campaign.is_active
      })
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "Atualizado com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          })
          this.search()
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao atualizar. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    async copyURL(campaign) {
      try {
        await navigator.clipboard.writeText(this.getLink(campaign));
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Sucesso",
            text: "Link copiado para àrea de transferência!",
            icon: "CoffeeIcon",
            variant: "success",
          },
        })
      } catch($e) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao copiar o link.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    sort(event) {
      if (!event.sortDesc && this.sortField == event.sortBy) {
        this.order = undefined;
        this.sortField = null;
      } else {
        var direction = event.sortDesc ? "desc":"asc";
        this.order = [event.sortBy , direction];
        this.sortField = event.sortBy;
      }
      this.search();
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
